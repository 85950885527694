body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scrollbar-gutter: stable both-edges; */
  overflow: scroll
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
    font-family: 'Montserrat-Regular';
    src: local('Montserrat-Regular'), url('./themes/fonts/Montserrat-Medium.ttf') format('truetype');
} */

